import React from "react";
import { Row, Col } from "react-bootstrap";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa";

import { Box } from "../../components/Core";
import PostCard from "../../components/PostCard";
import Pagination, { PageItem } from "../../components/Pagination";

import imgVehicles from "../../assets/image/jpeg/support-vehicles.jpg";
import imgContracts from "../../assets/image/jpeg/support-contracts.jpg";
import imgCustomers from "../../assets/image/jpeg/support-customers.jpg";

const CustomersListSidebar = () => (
  <>
    {/* <!-- Blog section --> */}

    <Row className="align-items-center justify-content-center">
      {/* <Col lg="12" className="mb-5">
        <PostCard
          horizontal
          img={imgContracts}
          preTitle="Nov 14, 2020"
          title="Create A New Contract"
          readMore
          link={"/support-new-contract"}
        >
          Create A New Contract Using "Sign This Device".
        </PostCard>
      </Col>

    
<Col lg="12" className="mb-5">
        <PostCard
          horizontal
          img={imgB3}
          preTitle="Nov 14, 2020"
          title="How did we get 1M+ visitors in 30 days without anything!"
          readMore
        >
          Throwing consider dwelling bachelor joy her proposal laughter.
          Raptures returned disposed one entirely her men ham.
        </PostCard>
      </Col>

      <Col lg="6" className="mb-5">
        <PostCard
          img={imgB1}
          preTitle="Nov 14, 2020"
          title="How did we get 1M+ visitors in 30 days without anything!"
          readMore
        >
          Throwing consider dwelling bachelor joy her proposal laughter.
          Raptures returned disposed one entirely her men ham.
        </PostCard>
      </Col>
      <Col lg="6" className="mb-5">
        <PostCard
          img={imgB2}
          preTitle="Nov 14, 2020"
          title="How did we get 1M+ visitors in 30 days without anything!"
          readMore
        >
          Throwing consider dwelling bachelor joy her proposal laughter.
          Raptures returned disposed one entirely her men ham.
        </PostCard>
      </Col>
      <Col lg="12" className="mb-5">
        <PostCard
          horizontal
          img={imgB3}
          preTitle="Nov 14, 2020"
          title="How did we get 1M+ visitors in 30 days without anything!"
          readMore
        >
          Throwing consider dwelling bachelor joy her proposal laughter.
          Raptures returned disposed one entirely her men ham.
        </PostCard>
      </Col>
      <Col lg="6" className="mb-5">
        <PostCard
          img={imgB4}
          preTitle="Nov 14, 2020"
          title="How did we get 1M+ visitors in 30 days without anything!"
          readMore
        >
          Throwing consider dwelling bachelor joy her proposal laughter.
          Raptures returned disposed one entirely her men ham.
        </PostCard>
      </Col>
      <Col lg="6" className="mb-5">
        <PostCard
          img={imgB5}
          preTitle="Nov 14, 2020"
          title="How did we get 1M+ visitors in 30 days without anything!"
          readMore
        >
          Throwing consider dwelling bachelor joy her proposal laughter.
          Raptures returned disposed one entirely her men ham.
        </PostCard>
      </Col>
       */}
    </Row>
  </>
);

export default CustomersListSidebar;
